<template>
  <div class="page oauth-page">
    <div class="oauth-page__inner">
      <div class="oauth-page__loading">
        <span>Авторизация...</span>
        <IconComponent category="default" name="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ME from "gql/auth/me.graphql";
import axios from "axios";

export default {
  components: { IconComponent },
  async mounted() {
    let token = this.$route.query.token;
    if (token) {
      this.$store.state.apollo_token = token;
      axios({
        method: "post",
        url: "/token",
        data: {
          token: token,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      await this.$apollo.provider.defaultClient
        .query({
          query: ME,
          context: {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        })
        .then(({ data }) => {
          this.$store.state.user = data.me;
        });
      this.goHome();
    } else {
      this.goHome();
    }
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="stylus">
.oauth-page {
  min-height 50vh
  display flex
  flex-direction column
  align-items center
  justify-content center
  padding: 30px

  +below(480px) {
    padding: 30px 15px
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .icon {
      width 20px
      height 20px
    }
  }
}
</style>
